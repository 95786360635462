import {Col, Row, Card, Button,Container} from 'react-bootstrap'


const timelineData = [
    {
        Year: "2025",
        Items: 
            [
                {
                    Title: "Console Log Parser (In-Progress)",
                    Description: "Nothing more painful than searching through lengthy build logs for your specific error! Refactoring a C# tool console log parser I've built to expand its integration beyond TeamCity and Slack as well as implement a user-friendly UI for RegEx input instead of specific directory folders.",
                    ImageSource:"",
                    Link:""
                }
            ]
    },
    {
        Year: "2024",
        Items: 
            [
                {
                    Title: "Personal Site, React Revival",
                    Description: "Refactoring and Updating old HTML/CSS based portfolio website to use newer React Framework and AWS hosting for better maintainance and scaling.",
                    ImageSource:"",
                    Link:""
                },
                {
                    Title: "Godot/Unity Game (In-Progress)",
                    Description: "Creating simple animation framework and exploring game mechanics in an iterative, user-feedback driven A/B testing manner. In the process of migrating from Unity to Godot.",
                    ImageSource:"images/prototype/drunkgoose.gif",
                    Link:""
                },
                {
                    Title: "Unity Project (In-Progress)",
                    Description: "Re-visit of an old reflective project called 'Stay Warm' made in Unity. Focus on animation and immersive ambience.",
                    ImageSource:"images/prototype/matchstyxproto.gif",
                    Link:""
                },
                {
                    Title: "Journey to the Chapel",
                    Description: "Quick weekend challenge learning Godot game engine, inspired by a VMA performance.",
                    ImageSource:"images/prototype/roan.gif",
                    Link:"https://ihmayor.itch.io/journey-to-the-chapel"
                },
                {
                    Title: "Potion Barista Assassin",
                    Description: "2-hour Quick & Dirty Console prototype exploring risk management mechanics",
                    ImageSource:"images/prototype/potionbarista.gif",
                    Link:""
                },
                {
                    Title: "DNA Parser/Sticker Shuffle",
                    Description: "Exploring memorize and bluff game mechanics in quick prototypes.",
                    ImageSource:"images/prototype/cardprompt.gif",
                    Link:"https://ihmayor.itch.io/sticker-shuffle"
                },
                {
                    Title: "Pobby Spot-Em",
                    Description: "Exploring Unity Cinemachine Camera and how to seamlessly transition from different camera perspectives based on independent trigger events",
                    ImageSource:"images/prototype/cinemachinetest.gif",
                    Link:"https://ihmayor.itch.io/plobby"
                },
                {
                    Title: "EncROACH",
                    Description: "Exercise in dynamic chain simulation between two independently moving 3D objects",
                    ImageSource:"images/prototype/roachproto.gif",
                    Link:"https://ihmayor.itch.io/encroach"
                }
            ]
    },
    {
        Year: "2023",
        Items: 
            [
                {
                    Title: "Silo Simulator",
                    Description: "Quick console-based game prototype based on gamifying software development in uncommunicative silos",
                    ImageSource:"",
                    Link:""
                },

                {
                    Title: "VBA Data Query and Prediction Exercise",
                    Description: "VBA Excel add-in querying from scraped data for contest poll predictions",
                    ImageSource:"",
                    Link:""
                }
            ]
    },
    {
        Year: "2022",
        Items: 
            [
                {
                    Title: "Secret Santa 2022",
                    Description: "Old school e-card for a friend's gift exchange",
                    ImageSource:"images/giphy_backups/secretsanta2022.gif",
                    Link:""
                }
            ]
    },
    {
        Year: "2021",
        Items: 
            [
                {
                    Title: "DND Combat Tracker",
                    Description: "React Web Application exercise to create a personal light-weight RPG Turn Tracker with specific custom features to run my own TTRPG games",
                    ImageSource:"",
                    Link:""
                }
            ]
    },
    {
        Year: "2020",
        Items: 
            [
                {
                    Title: "Dungeons and Dividends",
                    Description: "AR Unity Simulation Comic-book style story.",
                    ImageSource:"images/comicflow.gif",
                    Link:""
                },
                {
                    Title: "Audiobooks in Transit",
                    Description: "Unity Simulation of a fake entry for an Audible marketing campaign contest. The entry described creating pop-up stands that offer free audiobook samples for waiting commuters",
                    ImageSource:"images/prototype/audibleproto.gif",
                    Link:""
                },
                {
                    Title: "Sam Tap: Samsung Smart Watch and Smart TV connection",
                    Description: " A collaborative project at the CDM with Samsung to deliver a proof-of-concept Samsung smart watch feature that demonstrates connectivity with Samsung Smart T.V's through NFC readers.",
                    ImageSource:"images/SamTap.png",
                    Link:""
                },
                {
                    Title: "C.A.T: Clouds Awakening Trouble",
                    Description: "Block-stacking strategy mobile game made in Unity, prototyped for both IOS/Android",
                    ImageSource:"images/giphy_backups/CatGame.gif",
                    Link:"https://ihmayor.itch.io/cat-stacking-game"
                },
                {
                    Title: "Secret Santa 2020",
                    Description: "Quick old-school E-Card inspired game for a friend's gift exchange",
                    ImageSource:"images/giphy_backups/secretsanta2020.gif",
                    Link:""
                }
            ]
    },
    {
        Year: "2019",
        Items: 
            [
                {
                    Title: "Portfolio Website",
                    Description: "First iteration of portfolio website. Awarded Engineering Scholarship for best portfolio submission of that cohort for Centre of Digital Media's Graduate Program.",
                    ImageSource:"",
                    Link:""
                }
            ]
    },
    {
        Year: "2018",
        Items: 
            [
                {
                    Title: "Stay-Warm",
                    Description: "Desktop puzzle platformer game in Unity Engine exploring the feeling of trying to stay warm in a cold time-ticking environment",
                    ImageSource:"images/giphy_backups/PushStayWarm.gif",
                    Link:"https://ihmayor.itch.io/stay-warm-01"
                },
                {
                    Title: "Toph Walls",
                    Description: "Conceptual Future Artifact & Sci-Fi Short Story based off existing Tangible User Interface technology.",
                    ImageSource:"OldWebsite/CPSC601Backup/Wall/Full_Compo.png",
                    Link:"https://www.irenehmayor.com/Experimental/Wall"
                },
            ]
    },
    {
        Year: "2017",
        Items: 
            [
                {
                    Title: "Space Launch Music Box",
                    Description: "Single Button Rythm Game in a WPF Application curated for in-class assigned client",
                    ImageSource:"images/projectmusicbox.png",
                    Link:"https://github.com/Ihmayor/One_Button_Design_Challenge"
                },
                {
                    Title: "Sam the Snake",
                    Description: "Arduino powered interactive neck-pillow plushie with touch sensors and servos for head nodding",
                    ImageSource:"images/IMG_1005.png",
                    Link:"https://github.com/Ihmayor/Interactive_Wearable_Challenge_Arduino"
                },
                {
                    Title: "Tilt-eD: A Meditative Simulator and Data Visualizer",
                    Description: "Google Cardboard 360 Mobile App combining data visualization, 360 videos and meditative exercises. Specifically designed to be accessible for my own VR nausea sensitivity.",
                    ImageSource:"images/poster.png",
                    Link:""
                },
                {
                    Title: "Threaded Welcome",
                    Description: "Kinect Interaction app emulating a digital 'hygge' themed cross-stitch welcome greeting",
                    ImageSource:"images/embroidery.png",
                    Link:""
                },
                {
                    Title: "Interactive Graph Projects",
                    Description: "Various interactive data visualization projects focused on creating interactions that allow for user-friendly exploration of complex diverse data-points without the bias of aggregation.",
                    ImageSource:"images/giphy_backups/stateDemoP3_583_5.gif",
                    Link:"https://vimeo.com/249569850"
                },
                {
                    Title: "Met Muse: Data Story",
                    Description: "Data visualization project focused on how to transform large sets of raw data points into a more Tableau-ready format.",
                    ImageSource:"images/metmuse.gif",
                    Link:"https://www.irenehmayor.com/OldWebsite/MetMuse.html"
                }
            ]
    },
    {
        Year: "2016",
        Items: 
            [
                {
                    Title: "Flight Auction Booking System",
                    Description: "Website connected with a Microsoft SQL database with PHP and JavaScript simulating a flight auction system",
                    ImageSource:"images/airauction.png",
                    Link:"../OldWebsite/projectair.html"
                }
            ]
    },
    {
        Year: "2015",
        Items: 
            [
                {
                    Title: "SushiSushi",
                    Description: "WPF Tablet Sushi Ordering System.",
                    ImageSource:"images/rest.png",
                    Link:""
                }
            ]
    },
    {
        Year: "2014",
        Items: 
            [
                {
                    Title: "Endless Embers",
                    Description: "Roguelike Desktop Game in Java",
                    ImageSource:"images/eegameplay.png",
                    Link:"https://github.com/Ihmayor/Endless_Embers_Game"
                },
                {
                    Title: "Low-Level Assembly Shooting Game",
                    Description: "ARM Assembly Shooter Game with Read SNES Controller Input",
                    ImageSource:"",
                    Link:""
                }
            ]
    },
    {
        Year: "2013",
        Items: 
            [
                {
                    Title: "Paddocks",
                    Description: "My first python program. Based on recreating the game 'Paddocks'. Implemented multiple palette themes based on difficulty.",
                    ImageSource:"images/paddocks.jpg",
                    Link:""
                }
            ]
    }
]

const TimelineItem = ({data}) => ( 
    <div className="timeline-item">
        <h3 className="timeline-year"><u>{data.Year}</u></h3>
        <span className='circle'>
            <div className="timelineDiv"></div>
        </span>
        {
            data.Items.map((projectData, idx) => 
                <div key={idx} style={{maxWidth:"100%"}}>
                    <Card className="timeline-card">
                    <Card.Body>
                    <Card.Title>{projectData.Title}</Card.Title>
                        <Row>
                        <Col  xs={12} md={projectData.ImageSource != "" ? 7 : 12} >
                            <p> 
                                {projectData.Description}
                            </p>
                        </Col>
                        {projectData.ImageSource != "" && (
                            <Col  xs={12} md={2}> 
                                <img className='projectthumb' src={projectData.ImageSource}/>
                            </Col>
                        )}
                        
                        </Row>
                    {projectData.Link != "" && 
                    (<Button className='timeline-button' variant="dark" size="lg" href={projectData.Link} >
                        See More
                    </Button>)
                    }
                    </Card.Body>
                    </Card>
                    <br/>
                </div>
            )
        }
    </div>

)

export default function TimelineContent(){
    return (timelineData.length > 0 && (
        <div className="timeline-content-container">
            {timelineData.map((data,idx) => (
                <TimelineItem data ={data} key={idx}/>
            ))}
        </div>
    )
)
}    