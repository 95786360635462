import {Col, Row, Container, Button, Card} from 'react-bootstrap'
import ParagraphPanel from '../components/ParagraphPanel';
import './ArchivedTimeline.css'
import React from 'react';
import TimelineContent from '../pages/AcrhivedTimelineContent';



export default function ArchivedTimeline(){
    return(
        <div>
            <ParagraphPanel
                titlePanel="Project Timeline Archive"
                textPanel="A complete overview of my personal programming projects"
                borderVariant="default"
            />
            <Container>
                <div className="timeline-container">
                    <TimelineContent/>
                </div>
            </Container>
        </div>
    );
}