import {Col, Row, Container, Button} from 'react-bootstrap'
import ParagraphPanel from "../components/ParagraphPanel";

export default function DataVisualization() {
    return (
       <div>
            <ParagraphPanel
                titlePanel="Data Visualization Projects"
                textPanel=""
                borderVariant="default"
            />
                        <Container>
                <Row>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/cpsc599logo.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <img className='gameImage' src="../images/metmuse.gif" />
                        <p className='gameParagraph'>This was a data visulization project using <b>Tableau, Trifacta Data Wrangler and Jupyter Notebook</b> over 500,000 data points from open-source Metropolis Museum Data to produce a cohesive visual story answering key research questions exploring the data.</p>
                        <Button variant="dark" size="lg" href="../OldWebsite/MetMuse.html" >Link to Data Visual Story</Button>
                    </Col>
                </Row>
            </Container>
       </div>
    );
}