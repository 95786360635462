import {Col, Row, Container, Button, Card} from 'react-bootstrap'

import ParagraphPanel from "../components/ParagraphPanel";
import './Papers.css'

export default function Papers() {
    return (
       <div>
            <ParagraphPanel
                titlePanel="Conference Paper Contributions"
                textPanel=""
                borderVariant="default"
            />
            <Container>
                <h2>Academic Work</h2>
                <h4><i>Agile Surface Engineering Lab: University of Calgary</i></h4>
                <Row>
                    <h4><u>SMA-RT: Tool for Social Media Analysts</u></h4>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/pdfpreview/smartpdf.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <img className='gameImage' src="../images/pdfpreview/smartpdf2.png" />
                        <p className='gameParagraph'>This was a web application prototype supported by <b>Bootstrap</b>, Python scripts and a <b>MongoDB</b> database researching a system that could improve the experience of social media analysts who work during emergency operations. I worked on this project as a student developer providing the back-end support (SignalR) between the front-end and the python scripts. I created its <b>geolocation alogrithm to geolocate content based on text data</b> alone.</p>
                        <Button variant="dark" size="lg" href="../Social_Media_Analyst_Responding_Tool_A_V.pdf" >Link to full paper</Button>
                    </Col>
                </Row>
                <Row>
                    <h4><u>EOC-F: Emergency Operation Center of the Future</u></h4>
                    <Col xs={12} md={5}>
                        <img className='gameImage' src="../images/pdfpreview/surfacepdf.png" />
                        <br />
                        <br /> <br />
                    </Col>
                    <Col xs={12} md={5}>                
                        <p className='gameParagraph'>This was an <b>IOT</b> project that involved multi-surface devices such as: Touch TV, Smart Tables, Kapp Boards, Surface Tablets, Kinect, Hololens. My work included expanding the internal SDK/API that supported communication between devices, automated tests and feature work-arounds from external API limitations.</p>
                        <Button variant="dark" size="lg" href="../CADMICS1.pdf" >Link to full paper</Button>
                    </Col>
                </Row>
            </Container>
       </div>
    );
}

